import ApiResource, { IModel } from '@/resources/ApiResource';

class EventResource extends ApiResource {}

export default new EventResource('/event');

export interface IEventModel extends IModel {
    id: number;
    name: string;
    description: string;
    start_date: string;
    end_date: string;
    is_archived: boolean;
}
